/*
 * @Descripttion: *
 * @version: *
 * @Author: Arvin
 * @Date: 2020-11-18 18:48:23
 * @LastEditors: Arvin
 * @LastEditTime: 2020-11-19 11:33:25
 */
import Gender from './gender.vue'
export default Gender
